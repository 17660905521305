@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ion-title{
//     text-align: center !important;
// }

ion-app.cameraView,
ion-app.cameraView ion-content,
ion-app.cameraView .nav-decor {
  --background: transparent none !important;
}

// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
:root,
:root[mode="md"],
:root[mode="ios"] {
  --ion-font-family: "Quicksand", sans-serif;
  font-family: var(--ion-font-family) !important;
}

// ion-menu ion-content {
//   --background: var(--ion-item-background, var(--ion-background-color, #fff));
// }
// .icon-menu {
//     margin-right: 5px;
// }
// ion-menu.md ion-content {
//   --padding-bottom: 20px;
// }
// ion-menu.md ion-list {
//   padding: 20px 0 0 0;
// }
// ion-menu.md ion-note {
//   line-height: 24px;
//   margin-bottom: 20px;
// }
// ion-menu.md ion-item {
//   --padding-start: 16px;
//   --padding-end: 16px;
//   --min-height: 50px;
// }
// ion-menu.md ion-item.selected ion-icon {
//   color: var(--ion-color-primary);
// }
// ion-menu.md ion-item ion-icon {
//   font-size: 24px;
//   color: #73849a;
// }
// ion-menu.md ion-list#labels-list ion-list-header {
//   margin-bottom: 8px;
// }
// ion-menu.md ion-list-header,
// ion-menu.md ion-note {
//   padding-left: 16px;
//   padding-right: 16px;
// }
// ion-menu.md ion-note {
//   margin-bottom: 8px;
// }
// ion-menu.ios ion-content {
//   --padding-bottom: 20px;
// }
// ion-menu.ios ion-list {
//   padding: 20px 0 0 0;
// }
// ion-menu.ios ion-note {
//   line-height: 24px;
//   margin-bottom: 20px;
// }
// ion-menu.ios ion-item {
//   --padding-start: 16px;
//   --padding-end: 16px;
//   --min-height: 50px;
// }
// ion-menu.ios ion-item.selected ion-icon {
//   color: var(--ion-color-primary);
// }
// ion-menu.ios ion-item ion-icon {
//   font-size: 24px;
//   color: #73849a;
// }
// ion-menu.ios ion-list#labels-list ion-list-header {
//   margin-bottom: 8px;
// }
// ion-menu.ios ion-list-header,
// ion-menu.ios ion-note {
//   padding-left: 16px;
//   padding-right: 16px;
// }
// ion-menu.ios ion-note {
//   margin-bottom: 8px;
// }
// ion-note {
//   display: inline-block;
//   font-size: 16px;
//   color: var(--ion-color-medium-shade);
// }
// ion-item.selected {
//   --color: var(--ion-color-primary);
// }
// ion-avatar {
//   margin:10px;
// }
// .text-devider{
//   color: #232946 !important;
//   font-weight: 600;
// }

//   ================================================
/** Button **/
ion-button {
  --box-shadow: unset;
}

.ion-text-bold {
  font-weight: bold !important;
}

.no-shadow {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.w-100 {
  width: 100% !important;
}

.button-outline {
  --border-width: 1px !important;
}

.img-no-data {
  margin: auto;
  padding: 10%;
}

ion-fab {
  margin-bottom: 20px;
}

.buttonCss {
  button.alert-button:nth-child(1) {
    color: var(--ion-color-primary);
  }

  button.alert-button:nth-child(2) {
    color: var(--ion-color-danger);
  }
}

.searchbar-input {
  box-shadow: none !important;
  border: 0 !important;
}

// Ujian ======================================
.tutorial-ujian {
  --width: 100%;
  --height: auto;
}

.tutorial-ujian .alert-wrapper {
  max-width: 90%;
  .alert-head {
    text-align: center;
    background: var(--ion-color-primary);
    .alert-title {
      color: var(--ion-color-white);
    }
    .alert-sub-title {
      color: var(--ion-color-white);
    }
  }
  .alert-message {
    max-height: 100% !important;
  }
}

// .tutorial-ujian .alert-wrapper .alert-message {
//   max-height: 100% !important;
// }

// Alert Notif ===================
.notifOptions .alert-wrapper {
  --max-width: 500px !important;
  max-width: 500px !important;
  width: 80% !important;
}

.alert-guest {
  --width: 100%;
  --height: auto;
  .alert-wrapper {
    --width: 80%;
    max-width: 100%;
    border-radius: 10px;
    .alert-message {
      height: 100%;
      max-height: 100vh;
    }
  }
}

.notifOptions .alert-button-group-vertical {
  flex-direction: row !important;
}

.libur {
  color: var(--ion-color-danger) !important;
}

.reschedule {
  color: var(--ion-color-tertiary) !important;
}

// Modal Notif ===============
.modalNotif {
  --max-width: 95%;
  --max-height: 90%;
  --width: 95%;
  --height: 90%;
}

.modalNotif .modal-wrapper {
  border-radius: 8px;
  // --height: auto;
}

.swiper-slide img {
  // width: 100% !important;
  // max-width: 95% !important;
  // height: auto !important;
  // max-height: 100% !important;
  // display: block !important;
  // position: inherit !important;
  vertical-align: middle;
}

// Toast ====================
.toast-error {
  --border-radius: 5px !important;
  --background: #000000eb !important;
}

ion-toolbar .sc-ion-searchbar-ios-h {
  padding-top: 0;
  padding-bottom: 0;
  height: 52px;
}

.ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.toolbar-jawab {
  .item-jawab {
    ion-textarea {
      background: lavender;
      border-radius: 8px;
      margin: 10px 0;
      padding: 0 40px 8px 8px;
    }
  }
  ion-button {
    align-self: center !important;
    margin: 8px 16px;
  }
}

.card-jawaban {
  border: 1px solid #e2e2e2;
  padding: 8px 0;
  margin: 8px 16px;
  border-radius: 8px;
}
